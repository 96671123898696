<template>
    <div class="category-main">
        <div class="category-title">分类</div>
        <div class="category-list-box">
            <div class="cate-item" v-for="(item, key) in categories" :key="key">
                <el-popover placement="top-start" trigger="hover" @show="showEvt(key)" :ref="`tipPoper_${key}`"
                            popper-class="category-tooltip" v-if="item.id !== -1">
                    <div class="select-box">
                        <template v-if="categoryTooltip.length > 0">
                            <div class="col-ul" v-for="(cate_item, cate_key) in categoryTooltip" :key="cate_key">
                                <el-scrollbar style="height: 100%;">
                                    <div class="cate-unit" v-for="(val, ikey) in cate_item" :key="ikey"
                                         @mouseenter="showToolTip(cate_key, ikey)"
                                         @click="focusCateId(val.id)"
                                         :class="{ active : activeFunc(cate_key, val.id) }"
                                    >
                                        <div class="select-icon">
                                            <i class="el-icon-success" v-if="val.id === currentId"></i>
                                            <i class="null-circle" v-else></i>
                                        </div>
                                        <div class="cate-name"><span class="cate-txt" :class="{active: selectFunc(cate_key, val.id) }">{{val.label}}</span></div>
                                        <div class="select-icon">
                                            <i class="el-icon-arrow-right" v-if="val.sub.length > 0"></i>
                                        </div>
                                    </div>
                                </el-scrollbar>
                            </div>
                        </template>
                    </div>
                    <span class="cate-name" :class="{ active: currentId === item.id }" slot="reference" @click="focusCateId(item.id)">{{item.label}}</span>
                </el-popover>
                <span class="cate-name" :class="{ active: currentId === item.id }" @click="focusCateId(item.id)" v-else>{{item.label}}</span>
            </div>

        </div>
    </div>
</template>

<script>
import {chanmamaGoodsOfficialGetGoodsCategory} from "@/utils/apis";
export default {
    data() {
        return {
            categories: [],
            currentId: -1,
            spanId: -1,
            categoryTooltip: [],
            isShow: true,
            index: -1,
            activeIndex: [],
            selectIndex: []
        }
    },
    mounted() {
        this.categoryLists()
    },
    methods: {
        activeFunc(index, id) {
            if (this.activeIndex.length > 0 && this.activeIndex[index] && this.activeIndex[index].length > 0) {
                return this.activeIndex[index][0] === id
            } else {
                return false
            }
        },
        selectFunc(index, id) {
            if (this.selectIndex.length > 0 && this.selectIndex[index] && this.selectIndex[index].length > 0) {
                return this.selectIndex[index][0] === id
            } else {
                return false
            }
        },
        showEvt(key) {
            this.index = key
            this.categoryTooltip = []
            this.categoryTooltip.push(this.categories[key].sub)
        },
        focusCateId(id) {
            this.selectIndex = [...this.activeIndex]
            this.currentId = id
            this.$emit("cate", id)
        },
        showToolTip(key, skey) {
            this.activeIndex.splice(key)
            if (key === 0) {
                this.activeIndex.splice(key, 1, [this.categories[this.index].sub[skey].id])
                const list = this.categories[this.index].sub[skey].sub
                this.categoryTooltip.splice(key + 1)
                this.categoryTooltip.splice(key + 1, 1, [...list])
            }
            if (key > 0) {
                this.categoryTooltip.splice(key + 1)
                const item_list = this.categoryTooltip[key][skey]
                this.activeIndex.splice(key + 1, 1, [item_list.id])
                if (item_list.sub.length > 0) {
                    this.categoryTooltip.splice(key + 1, 1, [...item_list.sub])
                }
            }
            this.$nextTick(() => {
                this.$refs[`tipPoper_${this.index}`][0].updatePopper()
            })
            // console.log("activeIndex", this.activeIndex)
            // console.log("selectIndex", this.selectIndex)
        },
        async categoryLists() {
            try {
                const res = await chanmamaGoodsOfficialGetGoodsCategory()
                if (res.code === 200) {
                    this.categories = [{id: -1, label: "全部分类", sub: []}, ...res.data]
                }
                console.log("res", res)
            } catch (e) {
                this.$message.warning(res.msg)
            }


        }
    }
}

</script>

<style scoped lang="scss">
.category-main {
    & * {
        box-sizing: border-box;
    }
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .category-title {
        padding-left: 10px;
        padding-top: 10px;
        width: 100px;
        font-size: 12px;
        font-weight: 400;
        color: #999;
    }
    .category-list-box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .cate-item {
            span.cate-name {
                margin-bottom: 15px;
                margin-right: 15px;
                background-color: #fafafb;
                font-size: 12px;
                display: inline-block;
                padding: 5px 12px;
                border-radius: 15px;
                cursor: pointer;
            }
            .cate-name {
                &.active {
                    color: #1E33E3;
                }
            }
        }
    }
}
.select-box {
    & * {
        box-sizing: border-box;
    }
    box-sizing: border-box;
    height: 200px;
    font-size: 16px;
    padding: 6px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    ::v-deep .el-scrollbar {
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }

    }
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .col-ul {
        height: 200px;
        width: 200px;
        .cate-unit {
            height: 34px;
            cursor: pointer;
            padding: 0 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &.active {
                background-color: #EEEEEE;
            }
            &:hover {
                background-color: #EEEEEE;
            }
            .cate-name {
                margin-left: 5px;
                width: 142px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span.cate-txt {
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &.active {
                        color: #1E33E3;
                    }
                }
            }
            .select-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
